/* ANIMATIONS */

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-4rem) translate(50%,-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0rem) translate(50%,-50%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideX {
    0% {
        transform:translateX(-100%);
    }
    100% {
        transform:translateX(100%);
    }
}

@keyframes slideY {
    0% {
        opacity: 0;
        transform:translateY(-10px);
    }
    100% {
        opacity: 1;
        transform:translateY(0);
    }
}

@keyframes loader {
    0% {
        opacity: 0
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes disappear {
    from {
        height: 100vh;
        width: 100vw;
    }
    to {
        height: 0;
        width: 0;
    }
}

@keyframes scrollbar {
    from {
        overflow: hidden;
    }
    to {
        overflow: inherit;
    }
}