.calendly-badge-widget {
    cursor: none;
    height: 50px;
    overflow: hidden;
    position: inherit !important;
    z-index: 100 !important;
}

.calendly-badge-content {
    background: var(--gradient-color) !important;
    border-radius: 2px !important;
    font-family: var(--text-font) !important;
    font-weight: 500 !important;
    padding: 16px 30px !important;
}

#is-mobile {
    display: flex;
}

#first > .calendly-badge-widget {
    margin-top: inherit;
}

@media screen and (min-width: 1000px){
    .calendly-badge-widget {
        margin-top: 22px;
    }
}

@media screen and (max-width: 1000px) {
    #first {
        display: none;
    }
    .calendly-badge-widget {
       margin-top: 2vh;
    }
}