.title-section-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.title-section-block > * {
    color: var(--third-color);
}

.title-section-block > h3 {
    font-weight: 300;
    padding-left: 12px;
}

.title-section-block > svg {

}

@media screen and (max-width: 800px){
    .title-section-block {
        justify-content: center;
        width: 100%;
    }
}