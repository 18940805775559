.black-filter {
    background: var(--primary-color);
    height: 100%;
    position: absolute;
    opacity: 0.4;
    width: 100%;
}

.featured-projects-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 3% 8% 3% 8%;
    position: relative;
}

.featured-projects-container > h2 {
    font-size: 62px;
    padding-bottom: 2%;
}

.featured-project > h3, .featured-project > h4 {
    display: none;
}

.featured-project-holder-more {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.featured-project-holder-more > svg {
    background: -webkit-linear-gradient(rgba(41,152,255,1), rgba(0,255,136,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    margin-top: 5vh;
}

.featured-projects-holder {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    width: 85%;
}

.featured-project {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    position: relative;
    width: 20vw;
}

.featured-project > img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: -1;
}

.featured-project-resume {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    transition: display 1s ease-in-out;
    z-index: 11;
}

.featured-project-resume span:first-of-type {
    margin-top: 2em;
}

.featured-project-resume span:last-of-type {
    margin-bottom: 2em;
}

.featured-project-resume span {
    color: white;
    font-family: var(--text-font);
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.featured-project-resume p {
    color: white !important;
    text-align: center;
}

.hover-project {
    display: flex;
}

.hover-featured-project {
    animation-name: upProject;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    transition: transform 0.2s ease-in;
}

.see-more-project {
    background: -webkit-linear-gradient(rgba(41,152,255,1), rgba(0,255,136,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
    margin-top: 2vh;
    margin-bottom: 5vh;
    width: 100%;
}

@media screen and (max-width: 800px){
    .featured-projects-holder {
        flex-direction: column;
    }
    .featured-project {
        height: 200px;
        margin-top: 4vh;
        width: 350px;
    }
    .featured-projects-container > h2 {
        font-size: 42px;
        text-align: center;
    }
    .featured-project-holder-more > svg {
        font-size: 46px;
        margin-top: 3vh;
    }
}

@keyframes upProject {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20px);
    }
}