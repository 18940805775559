.actions-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5% 8% 5% 8%;
}

.action-row, .actions-container {
    align-items: center;
    display: flex;
}

.action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    width: 100%;
}

.action-logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-block: 1em;
}

.action-logo img {
    width: auto;
    height: 50px;
    margin-inline: 0.5em;
}

@media screen and(max-width: 1000px){
    .action-resume {
        justify-content: center;
        align-items: center;
    }
}

.actions-container > .action-row:not(:first-child){
    padding-top: 20vh;
}

.actions-container > .action-row:nth-child(2n) > .action-visual {
    transform: rotate(10deg);
}

.actions-container > .action-row:nth-child(2n) > .action-visual > .action-visual-rotate {
    transform: rotate(-6deg);
}

.action-mobile-resume {
    display: none;
    flex-direction: row;
    justify-content: center;
    padding-top: 10vh;
}

.action-mobile-resume > img {
    margin-right: 8px;
    transform: rotate(90deg);
    width: 5vw;
}

.actions-know-more {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 12px;
}

.actions-know-more > * {
    color: var(--third-color);
    font-weight: 600;
}

.actions-know-more > p {
    padding-left: 6px;
}

.action-resume {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.action-resume > p {
    text-align: justify;
    width: 90%;
}

.action-visual {
    background: var(--fourth-color);
    border-radius: 12px;
    height: 300px;
    transform: rotate(-10deg);
    width: 400px;
}

.action-visual-rotate {
    border-radius: 12px;
    height: 300px;
    transform: rotate(6deg);
    position: absolute;
    width: 400px;
}

.action-visual-rotate > img {
    border-radius: 12px;
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.actions-mobile {
    display: none;
}

.ellipse {
    position: absolute;
    width: 10%;
    z-index: 202;
}

.ellipse-right {
    right: -10%;
    top: 90%;
}

.ellipse-left {
    left: -10%;
    top: 90%;
}

.mobile-visual {
    display: none;
}

.desktop-visual {
   display: flex;
}

.actions-container > h1 {
    text-align: left;
    font-size: 3vw;
    width: 100%;
}

.display-know-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--gradient-color);
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 0;
    transition: height 1s ease-in-out;
    z-index: 1001;
}

.know-more-content {
    background: rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.display-know-more > div > svg {
    align-self: flex-end;
    margin: 2%;
    color: var(--text-primary-color);
    font-size: 32px;
    position: absolute;
    top: 10%;
    right: 10%;
}

.display-know-more > div > p {
    align-self: center;
    color: var(--text-primary-color);
    font-size: 4vw;
    text-align: center;
    display: none;
    opacity: 0;
    width: 80%;
    z-index: 10011;
}

.know-more-active {
    height: 100vh;
}

.know-more-active > div > p {
    display: flex;
    animation: fadeIn 1s ease-in-out 0.5s forwards;
    text-align: justify;
}

.know-more-active > div > p:first-of-type {
    margin-top: 15%;
}

@media screen and (min-width: 400px) and (max-width: 800px){
    .ellipse {
        display: none;
    }
}

@media screen and (min-width: 1000px){
    .display-know-more {
        display: none !important;
    }
}

@media screen and (max-width: 1000px){
    .actions-container {
        padding: 10% 8% 10% 8%;
    }
    .actions-container > h1 {
        display: none;
    }
    .actions-know-more {
        display: none;
    }
    .actions-mobile {
        display: flex;
    }
    .action-mobile-resume {
        display: flex;
    }
    .action-row {
        flex-direction: column;
    }
    .action-resume {
        width: 100%;
    }
    .action-resume > h2 {
        width: 100%;
    }
    .action-resume > * {
        text-align: center;
    }
    .action-resume > p {
        display: none;
    }
    .action-visual {
        margin-top: 40px;
    }
    .action-visual, .action-visual-rotate {
        height: 200px;
        width: 250px;
    }
    .mobile-visual {
        display: flex;
    }
    .desktop-visual {
        display: none;
    }
}

@media screen and (max-height: 600px){
    .know-more-active > div > p {
        font-size: 3vw;
    }
}