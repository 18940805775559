#mobile-calendly {
    display: none;
}

@media screen and (max-width: 1000px){
    .btn-contact-rounded {
        display: flex;
    }
    #mobile-calendly > .calendly-badge-widget {
        animation: fadeIn 1s ease-in 3s forwards;
        display: block;
        position: fixed;
        right: 6%;
        bottom: 4%;
        overflow: hidden;
        opacity: 0;
        height: auto;
        width: auto;
    }
    #mobile-calendly > .calendly-badge-widget > .calendly-badge-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--gradient-color);
        border-radius: 50% !important;
        height: 65px;
        padding: inherit !important;
        width: 65px;
        font-size: 18px;
    }
}