.clipboard {
    background: var(--fourth-color);
    border-radius: 6px;
    color: var(--text-primary-color);
    display: flex;
    font-family: var(--text-font);
    font-size: 12px;
    opacity: 0;
    padding: 12px;
    position: absolute;
    right: -60%;
    text-align: center;
    top: -5%;
    transition: opacity 1s ease-in-out;
    width: 60% !important;
}

.clipboard-active {
    opacity: 0.6;
}

.footer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 340px;
    justify-content: center;
    padding: 3% 8% 1% 8%;
    position: relative;
    width: 100%;
}

.footer-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 5vh;
    width: 100%;
}

.footer-content-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.footer-content-column > h3 {
    font-size: var(--h3-size);
}

.footer-content-column > p {
    padding-top: 2vh;
    font-size: 18px;
    line-height: 10px;
    letter-spacing: 1px;
}

.footer-content > img {
    width: 10%;
}

.footer-content > hr {
    border: 1px solid var(--text-second-color);
    transform: rotate(90deg);
    opacity: 0.4;
    width: 100px;
}

.footer-content-social {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2vh;
}

.footer-content-social > a > svg {
    font-size: 38px;
    margin: 0 4px 0 4px;
}

.footer-copyright {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 1vh;
}

.footer-copyright > p {
    font-size: 18px;
    text-align: center;
}

@media screen and (max-width: 800px){
    .footer-container {
        justify-content: flex-start;
    }
    .footer-content {
        flex-direction: column;
    }
    .footer-content > hr {
        display: none;
    }
    .footer-content-column {
        padding-top: 5vh;
    }
    .footer-copyright > p {
        font-size: 8px;
    }
    .clipboard {
        display: none;
    }
}

@media screen and (max-width: 400px){
    .footer-content-column > p {
        font-size: 5vw;
    }
}