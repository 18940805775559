.banner-merch-container {
    align-items: center;
    background: var(--gradient-color);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 300px;
    padding: 0% 5%;
    margin: 3% 0 3% 0;
    width: 100%;
}

.banner-merch-container > hr {
    border-bottom: 1px solid var(--text-second-color);
    transform: rotate(90deg);
    opacity: 0.3;
    width: 230px;
}

.banner-merch-stat > h3 {
    font-size: 54px;
}

.banner-merch-stat > p {
    color: var(--text-primary-color);
    font-size: 1.5vw;
}

.banner-merch-stat > span {
    font-size: 3vw;
    font-family: var(--text-font);
    font-weight: 600;
    color: var(--text-primary-color);
}

.banner-merch-stat {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-merch-stat > * {
    text-align: center;
}

@media screen and (max-width: 800px){
    .banner-merch-container {
        flex-direction: column;
    }
    .banner-merch-container > hr {
        display: none;
    }
    .banner-merch-stat > p {
        font-size: 16px;
    }
    .banner-merch-stat > span {
        font-size: 28px;
    }
}