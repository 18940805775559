::placeholder {
    color: var(--text-primary-color);
    font-family: var(--text-font);
    opacity: 0.6;
}

input, select, textarea{
    color: var(--text-primary-color);
    font-family: var(--text-font);
}

textarea:focus, input:focus {
    color: var(--text-primary-color);
    font-family: var(--text-font);
}

input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background: transparent;
}

input:focus, textarea:focus {
    border-color: var(--third-color);
}


input[type=submit] {
    background: var(--gradient-color);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#loader-img {
    animation-name: loader;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10%;
}

.contact-block {
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.container {
    border-radius: 5px;
    background-color: transparent;
    padding: 20px;
    width: 50%;
}

.container > form {
    display: flex;
    flex-direction: column;
}

.container > form > label {
    font-family: var(--text-font);
    color: var(--text-primary-color);
    width: 100%;
}

.loader-container {
    animation-name: disappear;
    animation-delay: 3s;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    background: var(--primary-color);
    border-radius: 0 0 50% 0;
    height: 100vh;
    position: fixed;
    transform: scale(2);
    width: 100vw;
    z-index: 2001;
}

.contact-modal {
    position: absolute;
    display: none;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.contact-modal-active {
    display: flex;
}

.cookiesContent {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 70px;
}

.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
}

.cookiesContent > img {
    width: 82px;
    margin-bottom: 15px;
}

.cookiesContent > p {
    margin-bottom: 40px;
    font-size: 18px;
}

.accept {
    background: var(--gradient-color);
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: var(--text-primary-color);
    font-family: var(--text-font);
    box-shadow: 0px 6px 18px -5px var(--text-second-color);
}

.mv-black-filter {
    background: rgba(0, 0, 0, 0.35);
    display: none;
    position: fixed;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 101;
}

.filter-active {
    display: block;
}

@media screen and (min-width: 800px){
    html {
        overflow: hidden
    }
}

@media screen and (max-width: 800px){
    #loader-img {
        top: 40%;
        width: 20%;
    }
    form > div {
        transform: translateX(-60px);
    }
}

