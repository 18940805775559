/* ICON BURGER */

label {
    display: flex;
    flex-direction: column;
    width: 40px;
}

label span {
    background: var(--text-primary-color);
    border-radius: 10px;
    height: 5px;
    margin: 4px 0;
    transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

span:nth-of-type(1) {
    width: 50%;
}

span:nth-of-type(2) {
    width: 100%;
}

span:nth-of-type(3) {
    width: 75%;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(5px,0px)
}

input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg)
}

input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(18px,-7px) rotatez(45deg);
}

/* NAVBAR */

nav {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1% 5% 1% 1%;
    position: fixed;
    width: 100%;
    z-index: 1001;
}

#navbar-first-block {
    align-items: center;
    justify-content: flex-start;
}

#navbar-first-block > ul > a > li {
    font-weight: 300;
    margin: 0.5rem;
}

#navbar-first-block > a > img {
    width: 5vw;
    z-index: 101;
}

#navbar-second-block {
    align-items: center;
    justify-content: flex-end;
}

.nav-burger > ul > a > li {
    font-weight: 300;
    margin: 0.5rem;
}

.btn-mv {
    align-items: center;
    border-radius: 2px;
    color: var(--text-primary-color);
    display: flex;
    flex-direction: row;
    font-family: var(--text-font);
    font-size: 24px;
    justify-content: center;
    margin: 0.5rem;
}

.display-nav {
    display: flex;
    flex-direction: row;
    margin-left: 4rem;
    z-index: 101;
}

.nav-burger {
    width: 100%;
    height: 100%;
    position: relative;
}

.display-nav-burger {
    animation: fadeInLeft 1s ease-in-out forwards;
    display: flex !important;
    flex-direction: column;
    position: relative;
    text-align: center;
    top: 50%;
    right: 50%;
    width: auto;
    z-index: 101;
}

.hide-nav {
    display: none;
}

.navbar-block {
    display: flex;
    flex-direction: row;
}

/* LANG MODULE */

.popover-wrapper {
    position: relative;
    z-index: 10;
}

.popover-btn-lang {
    background: var(--fourth-color);
    flex-direction: column;
    height: 50px;
    justify-content: center;
    position: relative;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.9s ease-in-out;
    width: 50px;
}

.popover-content {
    background: #69696b6b;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    left: 10px;
    opacity: 0;
    padding: 12px;
    position: absolute;
    top: 30px;
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    visibility: hidden;
    width: auto;
}

.popover-content-active {
    opacity: 1;
    transform: translate(0, 20px);
    visibility: visible;
    z-index: -1;
}

.popover-content > p {
    padding-top: 6px;
}

.language-mobile {
    align-items: center;
    animation: fadeIn 1.2s ease-in-out forwards;
    display: none;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 101;
    right: 32vw;
    top: 70vh;
}

.language-mobile > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 12px;
    width: 40%;
}

.language-mobile > div > p {
    padding-top: 6px;
}

/* BURGER MODULE */

.btn-burger {
    padding-left: 16px;
    display: none;
    position: relative;
    z-index: 201;
}

.display-burger {
    display: flex;
}

.btn-burger > svg {
    font-size: 32px;
}

.lang-mobile {
    display: none;
}

.show-burger {
    background: var(--gradient-color);
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    transform: inherit;
    overflow-y: hidden;
    width: 0;
    z-index: 100;
}

.show-burger-active {
    width: 25vw;
}

/* RESPONSIVE */

@media screen and (min-width: 1000px){
    #navbar-first-block > ul > a > li:before {
        border-bottom: 2px solid var(--text-primary-color);
        content: '';
        display: block;
        padding-top: 1rem;
        position: absolute;
        transform: translate(0px, 30px);
        transition: ease-out 0.5s;
        width: 0;
    }
    #navbar-first-block > ul > a > li:hover:before {
        border-bottom: 2px solid var(--text-second-color);
        content: '';
        display: block;
        transform: translate(0px, 30px);
        width: 4rem;
    }
    .activeLink > li:before {
        width: 4rem !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 1400px){
    .display-nav-burger > a > li {
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    nav {
        padding: 3% 6% 1% 6%;
    }
    #navbar-first-block > ul > li {
        font-size: 24px;
    }
    #navbar-first-block > a > img {
        width: 20%;
    }
    .btn-burger {
        display: flex;
    }
    .display-nav, .btn-lang {
        display: none;
    }
    .show-burger-active {
        width: 100vw;
    }
    .lang-mobile {
        display: flex;
        flex-direction: row;
        transform: translateY(40px);
        align-self: center;
    }
    .lang-mobile > p {
        margin: 12px;
        color: var(--text-primary-color);
        font-weight: 600;
    }
    .popover-wrapper {
        display: none;
    }
    #navbar-first-block > ul > a > li:before {
        display: none;
    }
    #is-mobile {
        display: flex !important;
    }
    .language-mobile > h3 {
        font-size: 5vw;
    }
    .language-mobile > div > p {
        font-size: 4vw;
    }
}


