#home-picture {
    bottom: 0;
    opacity: 80%;
    position: absolute;
    right: 5%;
    width: 30%;
}

.cursor-zone { /* Zone for background transparent cursor gradient */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.home-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;
    padding: 6% 8% 6% 8%;
    position: relative;
    z-index: 1;
}

.home-block-introducing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.home-block-introducing > .btn-contact {
    margin-top: 3vh;
    transform: translateX(-12px);
    position: relative;
    z-index: 21;
}

.home-block-picture-mobile {
    display: none;
}

.home-merch {
    font-family: var(--title-font);
    font-size: calc(2em + 4vw);
    letter-spacing: -5px;
    line-height: 7vw;
}

.home-resume {
    color: var(--text-second-color);
    font-size: var(--h3-size);
    font-weight: 300;
    padding-top: 25px;
    width: 50vw;
}

.home-scroll {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
}

.home-scroll > p {
    margin-bottom: 10px;
}

.home-scroll > p, svg {
    color: var(--text-primary-color);
    font-weight: 600;
}

.home-scroll > svg {
    animation: slideY 1.5s cubic-bezier(0.39, 0.58, 0.57, 1) infinite;
}

.home-scroll > svg {
    font-size: 32px;
}

.transparent {
    background-image: url("../../assets/gradient-circle.webp");
    background-repeat: no-repeat;
    background-position: 0 -300px;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px var(--text-primary-color);
}

/* RESPONSIVE */

@media screen and (max-width: 800px){
    .block-filter-picture {
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .cursor-zone {
        display: none;
    }
    .home-block-introducing > .title-section-block, .home-resume, .home-block-picture {
        display: none;
    }
    .home-container {
        flex-direction: column;
        justify-content: center;
        margin: 10% 5% 6% 5%;
        transform: translateY(-10%);
    }
    .home-merch {
        font-size: 9vw;
        letter-spacing: -1px;
        line-height: 6vh;
        text-align: center;
        width: 100%;
    }
    .home-block-picture-mobile {
        align-items: center;
        background: var(--gradient-color);
        border-radius: 50%;
        display: flex;
        height: 115px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        padding: 3px;
        width: 115px;
    }
    .home-block-introducing {
        margin-top: 5vh;
    }
    .holder-picture-mobile {
        align-items: center;
        background: var(--primary-color);
        border-radius: 50%;
        display: flex;
        height: 110px;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        padding: 6px;
        width: 110px;
    }
    .transparent {
        background-position: 140px 30px !important;
        -webkit-text-stroke: 0.5px var(--text-primary-color);
    }
    #home-picture {
        position: inherit;
        width: 80%;
    }
}

@media screen and (max-width: 1100px) {
    .home-block-picture {
        display: none;
    }
}

@media screen and (max-height: 1000px) and (max-width: 800px){
    .home-merch {
        line-height: 8vh;
    }
}