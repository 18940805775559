@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Noto+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color : #00070D; /* BLACK */
  --second-color : #25292C; /* GREY DARK */
  --third-color : #2998FF; /* BLUE UNIQUE */
  --fourth-color: #69696B; /* GREY LIGHT */
  --gradient-color : linear-gradient(19deg, rgba(41,152,255,1) 30%, rgba(0,255,136,1) 100%); /* BLUE GREEN GRADIENT */
  --text-primary-color: #F4F1F1; /* LIGHT COLOR */
  --text-second-color: #C6C2C2; /* MEDIUM COLOR */

  --title-font : 'Noto Sans', sans-serif;
  --text-font : 'Montserrat', sans-serif;

  --h1-size: calc(0.75em + 0.85vw);
  --h2-size: calc(0.75em + 0.65vw);
  --h3-size: calc(0.75em + 0.45vw);
  --h4-size: calc(0.75em + 0.35vw);
  --text-size: calc(0.75em + 0.25vw);
  --min-size: calc(0.75em + 0.15vw);
}

@media screen and (max-width: 768px) {
  :root {
    --h1-size : 42px;
    --h2-size : 20px;
    --h3-size : 16px;
    --h4-size : 14px;
    --text-size : 12px;
    --min-size : 8px;
  }
}

/*::-webkit-scrollbar-track {*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
/*  background-color: #F5F5F5;*/
/*}*/

/*::-webkit-scrollbar {*/
/*  width: 12px;*/
/*  background-color: #F5F5F5;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
/*  background-color: #555;*/
/*}*/

#root {
  overflow-x: hidden
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
}

body {
  background-color: var(--primary-color);
}

h1, h2, h3, h4 {
  color: var(--text-primary-color);
  font-family: var(--title-font);
}

p {
  color: var(--text-second-color);
  font-family: var(--text-font);
}

ul, li {
  color: var(--text-primary-color);
  font-family: var(--title-font);
  font-size: var(--h3-size);
  list-style: none;
}

.custom-cursor-mv {
  align-items: center;
  background: var(--gradient-color);
  border-radius: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 50%;
  opacity: 1;
  position: fixed;
  pointer-events: none;
  top: 50%;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: 20px;
  z-index: 1001;
}

.section-title > h3 {
  color: var(--third-color);
}

/* RESPONSIVE */

@media screen and (min-width: 800px) {
  * {
    cursor: none;
  }
  html {
    scroll-behavior: smooth;
  }
}

@media screen and (max-width: 800px) {
  .custom-cursor-mv {
    display: none;
  }
}


