.bottom {
    display: flex;
}

.services-container {
    align-items: center;
    background: var(--second-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5% 8% 5% 8%;
}


.services-holder-svg {
    align-items: center;
    background: var(--second-color);
    border-radius: 50%;
    border: 1px solid var(--third-color);
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    width: 120px;
}

.services-resume-block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    transition: transform 1s ease-in;
    width: 100%;
}

.services-resume-block > * {
    padding-top: 12px;
}

.services-resume-block > h2 {
    font-size: var(--h2-size);
}

.services-resume-block > p {
    line-height: 26px;
    text-align: justify;
}


@media screen and (min-width: 1400px){
    .services-resume-block > p {
        width: 65%;
    }
}

@media screen and (max-width: 1000px) {
    .services-container {
        flex-direction: column;
        padding: 10% 8%;
    }
    .services-resume-block {
        align-items: center;
        text-align: center;
        padding: 3% 8% 3% 8%;
        width: 100%;
    }
}