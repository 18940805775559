.banner-estimate-container {
    align-items: center;
    background: var(--second-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5% 8% 5% 8%;
    width: 100%;
}

.banner-estimate-container > h2 {
    font-size: var(--h2-size);
    font-weight: 400;
}

.banner-estimate-container > hr {
    margin: 2vh 0 2vh 0;
    border: 1px solid var(--third-color);
    width: 250px;
}

.banner-estimate-container > p {
    font-size: 24px;
    font-weight: 300;
}

.banner-estimate-container > .btn-mv {
    font-size: 18px;
    font-weight: 600;
    margin: 3vh 0 2vh 0;
}

@media screen and (max-width: 800px){
    .banner-estimate-container > p {
        font-size: 16px;
        text-align: center;
    }
}